@keyframes App-logo-spin {
  0% {
    transform: rotate(0deg);
  }
  45% {
    transform: rotate(0deg);
  }
  48% {
    transform: rotate(10deg);
  }
  52% {
    transform: rotate(-10deg);
  }
  55% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
